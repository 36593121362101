<template>
  <p class="WISELY-HONESTLY-and">
    <span class="text-wrapper-5">WISE</span>
    <span class="text-wrapper-6">LY HO</span>
    <span class="text-wrapper-5">NEST</span>
    <span class="text-wrapper-6">
      LY
      <br />
      and PROFESSIONALLY
    </span>
  </p>
</template>

<script>
export default {
  name: "WiselyHonestlyAnd",
};
</script>

<style scoped>
.WISELY-HONESTLY-and {
  color: transparent;
  font-family: "Jost", Helvetica;
  font-size: 5vw;
  font-weight: 400;
  letter-spacing: 0.2vw;
  line-height: 1.2;
  position: absolute;
  top: 40%;
  left: 5%;
  width: 90%;
}

.WISELY-HONESTLY-and .text-wrapper-5 {
  color: #ffffff;
}

.WISELY-HONESTLY-and .text-wrapper-6 {
  color: #7b7b7b;
  font-weight: 300;
}

/* 반응형 웹 스타일 */
@media (max-width: 1200px) {
  .WISELY-HONESTLY-and {
    font-size: 6vw;
    top: 35%;
  }
}

@media (max-width: 768px) {
  .WISELY-HONESTLY-and {
    font-size: 7vw;
    top: 30%;
  }
}

@media (max-width: 576px) {
  .WISELY-HONESTLY-and {
    font-size: 8vw;
    top: 25%;
  }
}
</style>
