<template>
  <div>
    <!-- page-header -->
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/main_1.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h2 class="title">부동산관리</h2>
          <p>
            사업시행자의 <b>자산손실 및 이미지 훼손이 최소화</b>가 관리의
            원칙입니다.<br />
            법률검토와 사례 그리고 다양한 대안을 통해 상대방과 협상을 합니다.<br />민원
            및 리스크 관리를 통해 안정적인 사업환경을 구축합니다.
          </p>
        </div>
      </div>
    </div>
    <!-- // page-header -->

    <!-- section 6 -->
    <div class="section section-team">
      <div class="container section5">
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-10 ml-auto mr-auto">
              <img
                class="nav-align-center"
                alt="wisenese_service-2"
                src="/img/section_6.png"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="title">
                <h4 class="title">미분양 관리</h4>
                <p>• 매각, 임대차 관리</p>
                <p>• 개발사업권 양도양수제안</p>
                <p>• 입주자 및 매수자(임대인)관리, 민원 대응방안 검토</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- // section 6 -->
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
export default {
  name: "ourService3",
  bodyClass: "ourService3-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
    };
  },
};
</script>
<style scoped>
div {
  font-family: "Pretendard-Light";
}

.container.section5 .section-story-overview .image-container {
  height: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 100px;
}
</style>
