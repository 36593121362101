<template>
  <navbar position="fixed" type="white" :transparent="transparent" :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto">
    <template>
      <div>
        <router-link class="navbar-brand" to="/">
          <img class="n-logo" src="img/main-logo.png" alt="" />
          WISE NEST
        </router-link>
      </div>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <router-link to="greeting" class="nav-link">          
          <p>Greeting</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="organization" class="nav-link">
          <p>Organization</p>
        </router-link>
      </li>
      <drop-down
              tag="li"
              title="Our Service"
              class="nav-item"
      >
        <nav-link to="/ourService1">
          부동산 투자 자문
        </nav-link>
        <nav-link to="/ourService2">
          부동산 Value UP
        </nav-link>
        <nav-link to="/ourService3">
          부동산 관리
        </nav-link>
        <nav-link to="/ourService4">
          강의
        </nav-link>
      </drop-down>
      <li class="nav-item">
        <router-link to="contactUs" class="nav-link">
          <p>Contact Us</p>
        </router-link>
      </li>     
    </template>
  </navbar>
</template>

<script>
import { Navbar, DropDown, NavLink  } from '@/components';
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    DropDown,
    Navbar,
    NavLink,
    [Popover.name]: Popover
  }
};
</script>

<style scoped>
div {
  font-family: "Pretendard-Light";
}
.n-logo {
  width: 20px;
  padding-bottom: 3px;
}
</style>
