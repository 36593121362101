<template>
  <div>
    <!-- page-header -->
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/main_1.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h2 class="title">부동산 Value UP</h2>
          <p>
            와이즈네스트의 벨류-업 컨설팅은 기존 PM 업무의 틀을 벗어나
           고객의 리스크를 최소화하고 자산 가치증대를 약속합니다.
          </p>
        </div>
      </div>
    </div>
    <!-- // page-header -->

    <!-- section 3 -->
    <div class="section">
      <div class="container">
        <div class="section-story-overview">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-2">
                <div
                  class="image-container wise"
                  style="background-color: white"
                >
                  <h4 class="title text-center container-title">사업기획</h4>
                </div>
              </div>
              <div class="col-md-4 service-1">
                <h6>✓ 사업수지표는 정밀하게 준비되었나요?</h6>
                <h6>✓ 규모검토와 법률검토는 제대로 되었나요?</h6>
                <h6>✓ 사업일정에 대한 대안은 준비되었나요?</h6>
                <h6>
                  <b
                    >✓ 사업주가 전적으로 신뢰할 만큼 디테일하게 기획히야
                    합니다.</b
                  >
                </h6>
              </div>
              <div class="col-md-2">
                <div
                  class="image-container wise"
                  style="background-color: white"
                >
                  <h4 class="title text-center container-title">업체선정</h4>
                </div>
              </div>
              <div class="col-md-4 service-1">
                <h6>✓ 입찰경쟁을 통해 업체선정을 했나요?</h6>
                <h6>✓ 표준계약서를 준용해야 할까요?</h6>
                <h6>✓ 계약서는 현재보다 미래를 염두해서 작성했나요?</h6>
                <h6>
                  <b>✓ 사업주에 가장 유리한 조건으로 계약해야 합니다.</b>
                </h6>
              </div>
            </div>
            <br /><br />
            <div class="row align-items-center">
              <div class="col-md-2">
                <div
                  class="image-container wise"
                  style="background-color: white"
                >
                  <h4 class="title text-center container-title">건축설계</h4>
                </div>
              </div>
              <div class="col-md-4 service-1">
                <h6>✓ 사업이익에 가장 최선의 방법으로 설계가 되어있나요?</h6>
                <h6>✓ 단계별로 설계가 변경 되는 것이 당연한가요?</h6>
                <h6>✓ 시공사가 요청하는 것을 무조건 반영하고 계신가요?</h6>
                <h6>
                  <b>✓ 사업이익을 대변하는 가장 현명한 설계안이 필요합니다.</b>
                </h6>
              </div>
              <div class="col-md-2">
                <div
                  class="image-container wise"
                  style="background-color: white"
                >
                  <h4 class="title text-center container-title">시공사</h4>
                </div>
              </div>
              <div class="col-md-4 service-1">
                <h6>✓ 입찰지침서를 제대로 만들었나요?</h6>
                <h6>✓ 스펙 리스트는 보유했나요?</h6>
                <h6>✓ 시공사의 말을 어디까지 믿어야 하나요?</h6>
                <h6>✓ 설계변경이 당연하다고 생각하나요?</h6>
                <h6><b>✓ 도급계약 후에도 사업이 끝나는 것이 아닙니다.</b></h6>
              </div>
            </div>
            <br /><br />
            <div class="row align-items-center">
              <div class="col-md-2">
                <div
                  class="image-container wise"
                  style="background-color: white"
                >
                  <h4 class="title text-center container-title">인허가</h4>
                </div>
              </div>
              <div class="col-md-4 service-1">
                <h6>✓ 인허가청에서 반영하라는 것을 무조건 해야할까요?</h6>
                <h6>✓ 분담금을 무조건 내야할까요?</h6>
                <h6>✓ 인허가 변수를 사전에 파악할 수 없을까요?</h6>
                <h6>
                  <b>✓ 인허가를 직접 전두지휘하세요. 사업성이 극대화됩니다.</b>
                </h6>
              </div>
              <div class="col-md-2">
                <div
                  class="image-container wise"
                  style="background-color: white"
                >
                  <h4 class="title text-center container-title">신탁/금융</h4>
                </div>
              </div>
              <div class="col-md-4 service-1">
                <h6>✓ 약정서(계약서를) 변경할 수 없을까요?</h6>
                <h6>✓ 금융조건을 비교 해야할까요?</h6>
                <h6>✓ 수동적인 업무를 능동적으로 바꿀 수 없을까요?</h6>
                <h6>
                  <b
                    >✓ 신탁/금융과 긴밀히 협업하면 더욱 쾌적한 사업환경이
                    됩니다.</b
                  >
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- // section 3 -->

    <!-- section 4  timeline -->
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto">
            <img
              class="nav-align-center"
              alt="wisenese_service-4"
              src="/img/time_cons.png"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- // section 4 -->

    <!-- section 5 정비사업관리 -->
    <div class="section">
      <div class="container section6">
        <div class="row">
          <div class="col-md-12">
            <h3 class="title">정비사업관리</h3>
            <h5 class="discription">
              조합원이 궁금한것을 가장 쉽게 <b>알려드리고</b>, 조합원이
              불안한것을 가장 먼저 <b>해결하고</b>, 안정적으로 사업이 진행되도록
              투명하게 <b>관리하며</b>
              조합의 자산가치가 증대되는 방안을 제시합니다.
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <blockquote>
              <p class="blockquote blockquote-default">
                일정이 제대로 진행되고 있습니까?
              </p>
              <p class="blockquote blockquote-default">
                시공서(설계사)입찰과정은 투명하고 적법하게 진행되고 있습니까?
              </p>
              <p class="blockquote blockquote-default">
                설계변경 관리는 잘 되고 있습니까?
              </p>
              <p class="blockquote blockquote-default">
                사업비(분담금)는 안정적으로 유지되고 있습니까?
              </p>
              <p class="blockquote blockquote-default">
                위 모든 사항을 조합원 모두가 쉽고 편리하게 보시고 계신가요?
              </p>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
    <!-- //  section 5 정비사업관리 -->
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
export default {
  name: "ourService2",
  bodyClass: "ourService2-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
    };
  },
};
</script>
<style scoped>
div {
  font-family: "Pretendard-Light";
}

.service-1 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.service-1 h6 {
  font-weight: normal; /* 기본 값으로 설정 */
}

.container-title {
  margin-top: 0;
}
</style>
