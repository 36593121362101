<template>
  <p class="div-wrapper">
    <span class="span">
      ‘현명하고, 정직하고, 전문적으로’
      <br />
    </span>
    <span class="text-wrapper-3">
      세가지 모토는 진실을 밝혀 클라이언트를 돕고 싶은 와이즈네스트의 원칙입니다.
      <br />
      <br />
    </span>
    <span class="text-wrapper-4">
      자금이 있다면, 시장이 받쳐준다면, 입지가 좋다면 누구나 다 성공할 것이라는 일확천금의 시장 부동산개발사업을 모두가 기대하고 있습니다. “사람이 진정으로 원하는 것은 무엇인가＂라는 질문과 “보이는 정보가 정말로 사실인가” 라는 의문에서 업을 시작합니다. 사소한 것과 나중으로 미뤘던 것들을 정확하게 파악하고 창의적이고 진실된 아이디어를 통해 사업의 실체를 드러냅니다.
    </span>
  </p>
</template>

<script>
export default {
  name: "DivWrapper",
};
</script>

<style scoped>
.div-wrapper {
  color: transparent;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 2vw;
  font-weight: 400;
  letter-spacing: 0.2vw;
  line-height: 1.6;
  position: absolute;
  top: 30%;
  left: 5%;
  width: 90%;
}

.div-wrapper .span {
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 0.04vw;
}

.div-wrapper .text-wrapper-3 {
  color: #999999;
  font-family: "Pretendard-Light", Helvetica;
  font-weight: 300;
  letter-spacing: 0.04vw;
}

.div-wrapper .text-wrapper-4 {
  color: #999999;
  font-family: "Pretendard-Light", Helvetica;
  font-size: 1.6vw;
  font-weight: 300;
  letter-spacing: 0.03vw;
  line-height: 1.6;
}

/* 반응형 웹 스타일 */
@media (max-width: 1200px) {
  .div-wrapper {
    font-size: 2.5vw;
    line-height: 1.6;
    top: 25%;
  }

  .div-wrapper .text-wrapper-4 {
    font-size: 2vw;
  }
}

@media (max-width: 768px) {
  .div-wrapper {
    font-size: 3vw;
    line-height: 1.6;
    top: 20%;
  }

  .div-wrapper .text-wrapper-4 {
    font-size: 2.5vw;
  }
}

@media (max-width: 576px) {
  .div-wrapper {
    font-size: 4vw;
    line-height: 1.6;
    top: 15%;
  }

  .div-wrapper .text-wrapper-4 {
    font-size: 3vw;
  }
}
</style>
