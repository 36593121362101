<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/main_1.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">인사말</h1>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container"
                style="background-image: url('img/wn/ourservice-img.png')"
              ></div>
            </div>

            <div class="separator separator-primary"></div>
            <div class="col-md-6 text-center" >
              <!-- First image on the right side, above the article -->
              <h3 class="title main-title">현명하고, 정직하고, 전문적으로</h3>
              <P>
                우리는<br/>"사람이 진정으로 원하는 것은 무엇인가?"<br/> "보이는 정보가
                정말 사실인가?"라는 <br/>질문에서 출발합니다.
              </P>
              <p>
                사소한 것까지 정확히 파악하고,<br/>창의적이고 진실된 아이디어로
                <br/>사업의 본질을 드러냅니다.
              </p>
              <p>
                와이즈네스트는 풍부한 경험과 지속적인 자기개발로<br/>고객과 함께
                고민하며 같은 방향을 생각하는<br/>전문가 집단입니다.
              </p>
              <p>
                고객의 니즈를 반영하여<br/>진실된 정보와 가능한 사실만을 제공합니다.
              </p>
              <p>
                고객의 미래를 위해 노력하는 WISENEST가 되겠습니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
export default {
  name: "landing",
  bodyClass: "landing-page",
};
</script>
<style scoped>
div {
  font-family: "Pretendard-Light";
}

.title.main-title {
  padding-top: 10px;
}
</style>
