<template>
  <div>
    <div class="page-header clear-filter" filter-color="black">
      <parallax
        class="page-header-image"
        style="background-image: url('img/wn/main-img.png')"
      >
      </parallax>
      <div class="container">
        <div class="content-center brand">
          <h1 class="text-center" id="bname">WISENEST</h1>
          <h2 class="category">
            Wisely, honestly and professionally
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Parallax } from "@/components";

export default {
  name: "index",
  bodyClass: "index-page",
  components: {
    Parallax,
  },
};
</script>
<style scoped>
div {
  font-family: "Jost";
}

#bname {
  font-weight: 400;
  letter-spacing: 1.30px;  
  text-transform: uppercase;
}
</style>
