<template>
  <div class="frame">
    <div class="overlap-group-wrapper">
      <div class="overlap-group">
        <img class="analyst-img" alt="Analyst img" src="/img/wn/analyst-img.png" />
        <img class="simone-hutsch" alt="Simone hutsch" src="/img/wn/simone-hutsch-viu1us2qwq4-unsplash-1.png" />
        <img class="vector" alt="Vector" src="/img/wn/vector-4-2.png" />
        <div class="text-wrapper-7">Analyst</div>
        <p class="tasks-VE">
          사업성검토
          <br />
          토지분석(부동산 권리분석)
          <br />
          입지 및 시장분석
          <br />
          일정 Tasks관리
          <br />
          원가관리 및 VE검토
          <br />
          시공사선정 및 각종 입찰계약
          <br />
          하자관리
          <br />
          프로세스 표준화
          <br />
          프로젝트 위험 분석 및 이슈 관리
        </p>
      </div>
    </div>
    <div class="overlap-wrapper">
      <div class="overlap-2">
        <div class="text-wrapper-8">Negotiater</div>
        <p class="p">
          토지매입
          <br />
          인허가협업
          <br />
          금융조달
          <br />
          분양가 산정
          <br />
          분양관리
          <br />
          사업비(자금집행 등) 관리
          <br />
          입주 및 미분양 세대(호실) 관리
          <br />
          각종 민원 해결
          <br />
          사업수익금 등 정산
          <br />
          관계사 및 유관부서 관리
        </p>
        <img class="img" alt="Vector" src="/img/wn/vector-4-2.png" />
      </div>
    </div>
    <div class="overlap-wrapper-2">
      <div class="overlap-3">
        <div class="text-wrapper-8">Designer</div>
        <p class="p">
          -설계검토 및 대안 제시
          <br />
          -관련법규 영향성 검토 및 분석
          <br />
          -상품성 Develop 및 최적화
          <br />
          -인허가협업
        </p>
        <img class="vector-2" alt="Vector" src="/img/wn/vector-4-2.png" />
      </div>
    </div>
    <div class="div-2" />
  </div>
</template>

<script>
export default {
  name: "Frame",
};
</script>

<style scoped>
.frame {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 30px;
  overflow-x: scroll;
  padding: 0 10px;
}

.frame::-webkit-scrollbar {
  display: none; /* 스크롤바 숨김 */
}

.frame .overlap-group-wrapper,
.frame .overlap-wrapper,
.frame .overlap-wrapper-2 {
  flex: 0 0 auto;
  min-width: 300px;
  max-width: 100%;
}

.frame .overlap-group-wrapper {
  background-color: #212121;
  border-radius: 12px;
  height: auto;
  width: 100%;
  overflow: hidden;
}

.frame .overlap-group {
  position: relative;
}

.frame .analyst-img,
.frame .simone-hutsch {
  width: 100%;
  height: auto;
  display: block;
}

.frame .vector {
  width: 100%;
  height: 1px;
  position: absolute;
  top: 70%;
  left: 0;
}

.frame .text-wrapper-7 {
  color: #ffffff;
  font-family: "Jost", Helvetica;
  font-size: 4vw;
  font-weight: 500;
  position: absolute;
  top: 30%;
  left: 10%;
}

.frame .tasks-VE {
  color: #ffffff;
  font-family: "Pretendard-Light", Helvetica;
  font-size: 2vw;
  font-weight: 300;
  position: absolute;
  top: 50%;
  left: 10%;
}

.frame .overlap-wrapper {
  background-color: #121d25;
  border-radius: 12px;
  height: auto;
  width: 100%;
  overflow: hidden;
}

.frame .overlap-2 {
  background-image: url('/img/wn/negotiater-img.png');
  background-size: cover;
  height: auto;
  position: relative;
}

.frame .text-wrapper-8 {
  color: #ffffff;
  font-family: "Jost", Helvetica;
  font-size: 4vw;
  font-weight: 500;
  position: absolute;
  top: 10%;
  left: 5%;
}

.frame .p {
  color: #ffffff;
  font-family: "Pretendard-Light", Helvetica;
  font-size: 2vw;
  font-weight: 300;
  position: absolute;
  top: 30%;
  left: 5%;
}

.frame .img {
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
}

.frame .overlap-wrapper-2 {
  background-color: #212121;
  border-radius: 12px;
  height: auto;
  width: 100%;
  overflow: hidden;
}

.frame .overlap-3 {
  background-image: url('/img/wn/designer-img.png');
  background-size: cover;
  height: auto;
  position: relative;
}

.frame .vector-2 {
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
}

.frame .div-2 {
  height: auto;
  width: auto;
}

/* 반응형 웹 스타일 */
@media (max-width: 768px) {
  .frame {
    flex-direction: column;
  }

  .frame .overlap-group-wrapper,
  .frame .overlap-wrapper,
  .frame .overlap-wrapper-2 {
    width: 100%;
    margin-bottom: 30px;
  }

  .frame .text-wrapper-7,
  .frame .text-wrapper-8 {
    font-size: 6vw;
  }

  .frame .tasks-VE,
  .frame .p {
    font-size: 3vw;
  }
}

@media (max-width: 576px) {
  .frame {
    padding: 0 5px;
  }

  .frame .text-wrapper-7,
  .frame .text-wrapper-8 {
    font-size: 7vw;
  }

  .frame .tasks-VE,
  .frame .p {
    font-size: 4vw;
  }
}
</style>
