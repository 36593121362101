<template>
  <div :class="['greeting', `state-2-${state}`]">
    <div :class="['menu', divClassName]">{{ menu }}</div>
  </div>
</template>

<script>
export default {
  name: "Greeting",
  props: {
    menu: {
      type: String,
      default: "Menu",
    },
    state: {
      type: String,
      default: "default",
      validator: function (value) {
        return ["hover", "default"].indexOf(value) !== -1;
      },
    },
    divClassName: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.greeting {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  position: relative;
  width: 120px;
}

.greeting .menu {
  font-family: "Jost", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.greeting.state-2-default {
  padding: 0px 13px;
}

.greeting.state-2-hover {
  padding: 0px 12px;
}

.greeting.state-2-default .menu {
  color: #8a8a8a;
}

.greeting.state-2-hover .menu {
  color: #ffffff;
}
</style>
