<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/main_1.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h2 class="title">조직도</h2>
          <p>
            와이즈네스트는<br/>토지매입, 시행사, 설계사, 금융, 신탁, 시공사<br/>
            등의 다양한 경험을 갖춘 유능한 인재들입니다. <br/>끊임없이 소통하며
            최고의 시너지를 갖춥니다.
          </p>
        </div>
      </div>
    </div>
    <div class="section text-center">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="team-player">
              <h4 class="title">Consulting</h4>
              <h5 class="description">
                신규부동산사업 검토<br />
                토지매입 자문<br />
                금융조달 지원<br />
                공사비 검토<br />
                임대차 자문<br />
              </h5>
            </div>
          </div>
          <div class="col-md-4">
            <div class="team-player">
              <h4 class="title">Development</h4>
              <h5 class="description">
                민간부동산 개발 사업<br />
                정비사업관리<br />
                사업수익증대방안<br />
                VE
              </h5>
            </div>
          </div>
          <div class="col-md-4">
            <div class="team-player">
              <h4 class="title">Design</h4>
              <h5 class="description">
                기획설계<br />
                인허가 대관<br />
                Exterior/Interior Design
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
export default {
  name: "greeting",
  bodyClass: "greeting-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
    };
    
  },
};
</script>
<style scoped>
div {
  font-family: "Pretendard-Light";
}
</style>
