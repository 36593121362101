<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="javascript:;">E-MAIL: wisenests@naver.com</a>
          </li>
          <li>
            <router-link to="privacy" class="nav-link">
              <p>개인정보 처리방침</p>
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }}, WISENEST CO., LTD. ALL RIGHTS RESERVED.
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style scoped>
.container .nav-link {
  padding-top: 0px;
}
</style>