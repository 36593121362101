<template>
  <div class="WN-main">
    <div class="div-3">
      <Overlap />
      <div class="text-wrapper-9">Who Is WISENEST?</div>
      <div class="text-wrapper-10">Organization</div>
      <div class="text-wrapper-11">Our Service</div>
      <DivWrapper />
      <p class="text-wrapper-12">
        와이즈 네스트의 임직원은 토지매입, 시행사, 설계사, 금융, 신탁,
        시공사등의 다양한 경험을 갖춘 유능한 인재들입니다.
        <br />
        끊임없이 소통하며 최고의 시너지를 갖춥니다.
      </p>
      <p class="text-wrapper-13">
        숫자는 거짓말을 안한다는 말, 얼마나 믿을 수 있습니까. 누가 만든 것인가에
        따라 진실과 거짓의 양면성을 갖고 있습니다. 숫자를 가장 진실된 형태로
        만드는 현명한 방법 와이즈네스트에서만 가능합니다.
      </p>
      <p class="text-wrapper-14">
        와이즈네스트는  풍부한 경험을 바탕으로 지속적으로 자기개발을 도모하는
        전문가 집단입니다. 클라이언트와 함께 고민하고 같은 방향을 생각합니다.
      </p>
      <p class="text-wrapper-15">
        결과는 책상에서 만들어지는 것이 아니라 현장에서 만들어지는 것입니다.
        젊고 유능하며 실전경험이 풍부한 전문가 집단 그것이 와이즈네스트입니다.
      </p>
      <p class="text-wrapper-16">
        클라이언트가 행복한 미래를 만들 수 있도록 와이즈 네스트는 진실된 정보와
        가능한 사실만 만들겠습니다.
      </p>
      <WiselyHonestlyAnd />
      <img class="frame-5" alt="Frame" src="/img/wn/frame-22.svg" />
      <img class="frame-6" alt="Frame" src="/img/wn/frame-23.svg" />
      <img class="frame-7" alt="Frame" src="/img/wn/frame-24.svg" />
      <img class="frame-8" alt="Frame" src="/img/wn/frame-25.svg" />
      <img
        class="ourservice-img"
        alt="Ourservice img"
        src="/img/wn/ourservice-img.png"
      />
      <Frame />
      <div class="frame-9">
        <div class="text-wrapper-17">WISE NEST</div>
      </div>
      <div class="frame-10">
        <div class="text-wrapper-18">CONTACT</div>
      </div>
      <Gnb />
    </div>
  </div>
</template>

<script>

import DivWrapper from './components/DivWrapper';
import Frame from './components/Frame';
import Gnb from './components/Gnb';
import Overlap from './components/Overlap';
import WiselyHonestlyAnd from './components/WiselyHonestlyAnd';


export default {
  name: "WnMain",
  components: {
    DivWrapper,
    Frame,
    Gnb,
    Overlap,
    WiselyHonestlyAnd,
  },
};
</script>

<style scoped>
.WN-main {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.WN-main .div-3 {
  background-color: #000000;
  width: 100%;
  max-width: 1920px;
  padding: 20px;
  position: relative;
}

.WN-main .text-wrapper-9,
.WN-main .text-wrapper-10,
.WN-main .text-wrapper-11 {
  color: #ffffff;
  font-family: "Jost", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.6px;
  line-height: 45px;
  margin: 20px 0;
}

.WN-main .text-wrapper-12,
.WN-main .text-wrapper-13,
.WN-main .text-wrapper-14,
.WN-main .text-wrapper-15,
.WN-main .text-wrapper-16 {
  color: #999999;
  font-family: "Pretendard-Light", Helvetica;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.16px;
  line-height: 25.6px;
  margin: 20px 0;
}

.WN-main .frame-5,
.WN-main .frame-6,
.WN-main .frame-7,
.WN-main .frame-8 {
  height: 100px;
  width: 100px;
  margin: 20px;
}

.WN-main .ourservice-img {
  height: auto;
  width: 100%;
  max-width: 618px;
  margin: 20px 0;
}

.WN-main .frame-9,
.WN-main .frame-10 {
  background-color: #000000;
  height: 80px;
  width: 80px;
  position: fixed;
  right: 0;
  top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.WN-main .frame-10 {
  background-color: #003fff;
  top: 160px;
}

.WN-main .text-wrapper-17,
.WN-main .text-wrapper-18 {
  color: #ffffff80;
  font-family: "Jost", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  line-height: 20px;
  text-align: center;
  transform: rotate(-90deg);
  white-space: nowrap;
}

/* 반응형 웹 스타일 */
@media (max-width: 1200px) {
  .WN-main .text-wrapper-9,
  .WN-main .text-wrapper-10,
  .WN-main .text-wrapper-11,
  .WN-main .text-wrapper-12,
  .WN-main .text-wrapper-13,
  .WN-main .text-wrapper-14,
  .WN-main .text-wrapper-15,
  .WN-main .text-wrapper-16 {
    font-size: 18px;
    line-height: 28px;
  }

  .WN-main .frame-5,
  .WN-main .frame-6,
  .WN-main .frame-7,
  .WN-main .frame-8 {
    height: 80px;
    width: 80px;
  }

  .WN-main .frame-9,
  .WN-main .frame-10 {
    height: 60px;
    width: 60px;
  }
}

@media (max-width: 768px) {
  .WN-main .text-wrapper-9,
  .WN-main .text-wrapper-10,
  .WN-main .text-wrapper-11,
  .WN-main .text-wrapper-12,
  .WN-main .text-wrapper-13,
  .WN-main .text-wrapper-14,
  .WN-main .text-wrapper-15,
  .WN-main .text-wrapper-16 {
    font-size: 16px;
    line-height: 24px;
  }

  .WN-main .frame-5,
  .WN-main .frame-6,
  .WN-main .frame-7,
  .WN-main .frame-8 {
    height: 60px;
    width: 60px;
  }

  .WN-main .frame-9,
  .WN-main .frame-10 {
    height: 50px;
    width: 50px;
  }
}

@media (max-width: 576px) {
  .WN-main .text-wrapper-9,
  .WN-main .text-wrapper-10,
  .WN-main .text-wrapper-11,
  .WN-main .text-wrapper-12,
  .WN-main .text-wrapper-13,
  .WN-main .text-wrapper-14,
  .WN-main .text-wrapper-15,
  .WN-main .text-wrapper-16 {
    font-size: 14px;
    line-height: 22px;
  }

  .WN-main .frame-5,
  .WN-main .frame-6,
  .WN-main .frame-7,
  .WN-main .frame-8 {
    height: 50px;
    width: 50px;
  }

  .WN-main .frame-9,
  .WN-main .frame-10 {
    height: 40px;
    width: 40px;
  }

  .WN-main .text-wrapper-17,
  .WN-main .text-wrapper-18 {
    font-size: 12px;
  }
}
</style>