<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/main_1.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h2 class="title">Contact Us</h2>
        </div>
      </div>
    </div>
    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">문의사항</h2>
        <p class="description">Your project is very important to us.</p>
        <div class="row">
            <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
                <fg-input
                class="input-lg"
                placeholder="성명"
                v-model="form.name"
                name="name"
                addon-left-icon="now-ui-icons users_circle-08"
              ></fg-input>
              <fg-input
                class="input-lg"
                type="tel"
                name="phone"
                placeholder="연락처"
                v-model="form.phone"
                addon-left-icon="now-ui-icons users_circle-08"
              ></fg-input>
              <fg-input
                class="input-lg"
                type="email"
                name="email"
                placeholder="이메일"
                v-model="form.email"
                addon-left-icon="now-ui-icons ui-1_email-85"
              ></fg-input>
              <div class="textarea-container">
                <textarea
                  class="form-control"
                  name="message"
                  rows="4"
                  cols="80"
                  v-model="form.message"
                  placeholder="소중한 문의 내용에 빠르게 답변드리겠습니다. (2,000자 이내)"
                ></textarea>
              </div>
              <div class="send-button">
                <n-button
                  type="default"
                  round
                  block
                  size="lg"
                  @click="sendEmail"
                >
                  문의사항
                </n-button>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, FormGroupInput } from "@/components";

export default {
  name: "landing",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
        title: "",
      },
      serviceId: process.env.VUE_APP_EMAIL_SERVICE_ID,
      publicKey: process.env.VUE_APP_EMAIL_PUBLIC_KEY,
      templateId: process.env.VUE_APP_EMAIL_TEMPLATE_ID,
      userId: process.env.VUE_APP_EMAIL_USER_ID,
    };
  },
  methods: {   
    sendEmail() {
      const payload = {
        name: this.form.name,
        email: this.form.email,
        phone: this.form.phone,
        message: this.form.message
      }
      emailjs.send(this.serviceId, this.templateId, payload, this.publicKey)
        .then((res) => {
            console.log(res)
            alert("문의해 주셔서 감사합니다. 검토 후 연락 드리겠습니다.");
        }, (error) => {
            console.log(error);
            alert("일시적인 문제가 발생하였습니다, 관리자에게 문의 바랍니다.");
        })
    },
  
  },
};
</script>

<style scoped>
div {
  font-family: "Pretendard-Light";
}
</style>