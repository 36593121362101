<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/main_1.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h2 class="title">개인정보처리방침</h2>
        </div>
      </div>
    </div>
    <!-- // page-header -->
    <!-- second section -->
    <div class="section section-tabs">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h5 class="title">제1조(이용자의 개인정보 보호)</h5>
            <p>
              정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 및 관계
              법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게
              관리하고 있습니다. 이에 「개인정보 보호법」 제30조에 따라
              정보주체에게 개인정보의 처리와 보호에 관한 절차 및 기준을
              안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록
              하기 위하여 다음과 같이 개인정보 처리방침을 수립・공개합니다. 본
              개인정보취급방침은 관련 법령의 개정이나 정부의 지침, 회사의 정책
              등에 따라 변경될 수 있으므로 회사의 [홈페이지
              화면(https://www.wisenest.co.kr)]에서 수시로 확인할 필요가
              있습니다.
            </p>

            <p>
              회사의 개인정보취급방침은 다음과 같은 내용을 포함한다. <br />
              1. 수집하는 개인정보 항목 및 수집방법 <br />
              2. 개인정보 수집 및 이용 목적 <br />
              3. 개인정보의 보유기간 및 파기 <br />
              4. 수집한 개인정보의 공유 및 제공 <br />
              5. 정보주체의 권리 및 행사방법 <br />
              6. 개인정보의 기술적, 관리적, 물리적 대책 <br />
              7. 개인정보의 위탁 처리 <br />
              8. 개인정보 관련 의견수렴 <br />
              9. 개인정보 보호책임자 및 담당자의 소속, 성명 및 연락처 <br />
              10. 정보주체의 권리와 의무 <br />
              11. 변경에 관한 사항
            </p>

            <h5 class="title">제2조(개인정보 수집 및 이용 목적)</h5>
            <p>
              회사는 다음과 같은 목적으로 최소한의 개인정보를 수집/이용한다.
              <br />
              1.문의 신청 시 : 서비스의 계약 및 원활한 운영을 위해 아래와 같은
              정보를 수집합니다. <br />
              가. 필수 항목 : 성명, 연락처, 전자우편 주소 <br />
              2.서비스 이용과정이나 사업 처리 과정에서 아래와 같은 정보들이
              생성되어 수집될 수 있습니다 <br />
              가. 서비스 이용기록, 접속로그, 접속 IP, 서비스 이용내역 정보 등
              <br />
              3. 개인정보 수집방법 : 서비스 이용계약 및 서면
            </p>

            <h5 class="title">제3조(개인정보의 보유기간 및 파기)</h5>
            <p>
              수집된 개인정보는 다음 파기절차 및 방법에 따라 개인정보의 수집목적
              또는 제공받은 목적이 달성된 후 내부방침 및 기타 관련 법령 (상법,
              전자금융거래법 등)에 의한 정보보호 사유에 따라 일정기간 저장된 후
              파기된다. <br />
              1. 보유기간 : 제3조의 수집 및 이용 목적이 달성되고 아래와 같은
              관계 법령상의 보존기간이 종료되는 때까지 <br />
              가. 상업장부와 영업에 관한 중요서류에 포함된 개인정보 : 10년(상법)
              <br />
              나. 전표 또는 이와 유사한 서류에 포함된 개인정보 : 5년(상법)
              <br />
              다. 회원의 성명, 전화번호, 주소, 기타 거래에 관한 장부: 관련
              국세의 법정신고기한이 경과한 날로부터 5년(국세기본법) <br />
              라. 계약 또는 청약철회 등에 관한 기록 : 계약 또는 청약철회 시부터
              5년(전자상거래 등에서의 소비자보호에 관한 법률) <br />
              마. 대금결제 및 재화 등의 공급에 관한 기록 : 공급 시부터
              5년(전자상거래 등에서의 소비자보호에 관한 법률) <br />
              바. 표시광고에 관한 기록 : 6개월(전자상거래 등에서의 소비자보호에
              관한 법률) <br />
              사. 기타 관계 법령의 규정에 의하여 보존할 필요가 있는 정보는 그
              법령에서 정한 기간 동안 보존 <br />
              2. 파기절차 및 방법 <br />
              가. 회사는 수집된 개인정보를 안전하게 처리하며, 유출의 방지를
              위하여 다음과 같은 방법으로 개인정보를 파기한다. <br />
              나. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
              파기한다. <br />
              다. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는
              기술적 방법을 사용하여 삭제한다.
            </p>
            <h5 class="title">제4조(수집한 개인정보의 공유 및 제공)</h5>
            <p>
              수집된 개인정보를 이용자가 동의한 목적 또는 법률의 규정에 의하여
              제공(공유)ㆍ활용되는 경우 이외의 목적으로 이용하거나 외부에
              제공ㆍ공유하지 아니한다. 단, 다음의 경우에는 예외로 한다. <br />
              1. 범죄의 고소ㆍ고발 및 기타 관계법령에 의한 요청 <br />
              가.제공(공유)ㆍ활용 대상 : 수사기관, 검찰, 법원, 국세청,
              지방세무소 등 <br />
              2. 제3자 제공 가. 제공(공유)ㆍ활용 대상 : 이벤트 경품, 서적 등
              발송을 위한 대행사
            </p>
            <h5 class="title">제5조(정보주체의 권리 및 행사방법)</h5>
            <p>
              정보주체 또는 법정대리인은 언제든지 등록되어 있는 본인 또는 당해
              만14세 미만 아동의 개인정보를 열람하거나 정정 및 삭제 요청할 수
              있다. 다만, 법령상의 사유로 삭제가 불가능한 경우에는 그러하지
              아니하다. 정보주체 또는 법정대리인이 개인정보의 오류에 대한 정정을
              요청한 경우, 정정을 완료하기 전까지는 당해 개인정보를 이용 또는
              제공하지 않는다. 잘못된 개인정보를 제3자에게 이미 제공한 경우에는
              정정 처리결과를 제3자에게 지체 없이 통지하여 정정하도록 조치한다.
            </p>
            <h5 class="title">제6조(개인정보의 기술적/관리적/물리적 대책)</h5>
            <p>
              1. 기술적 대책 개인정보는 아이디와 비밀번호에 의해 보호되며 파일
              및 전송데이터를 암호화하거나 파일잠금기능(Lock)을 사용하여 중요한
              데이터는 별도의 보안기능을 통해 보호한다. 회사는 백신프로그램을
              이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고
              새로운 바이러스를 대비하여 백신프로그램을 주기적으로 업데이트한다.
              회사는 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게
              전송할 수 있는 보안장치를 채택하며, 해킹 등 외부침입에 대비하여
              방화벽을 설치하고, 정기적인 취약점 분석 점검을 통해 보안에 만전을
              기한다. <br />
              2. 관리적 대책 개인정보에 대한 접근권한을 최소한의 인원으로
              제한한다. 그 최소한의 인원에 해당하는 자는 다음과 같습니다. <br />
              가. 해당 정보를 직접 상대로 하여 업무를 수행하는 자 <br />
              나. 개인정보보호책임자 및 담당자 등 개인정보관리업무를 수행하는 자
              <br />
              다. 기타 업무상 개인정보의 처리가 불가피한 자 - 개인정보를
              처리하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보 보호
              의무 등에 관해 정기적인 사내 교육 및 외부 위탁교육을 실시한다.
              <br />
              - 입사 시 전직원의 보안서약서를 통하여 사람에 의한 정보유출을
              사전에 방지하고 개인정보보호 정책에 대한 이행사항 및 직원의
              준수여부를 감사하기 위한 내부절차를 마련한다. <br />
              - 개인정보 관련 처리자의 업무 인수인계는 보안이 유지된 상태에서
              철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한
              책임을 명확히 한다.<br />
              - 전산실 및 자료 보관실 등을 특별 보호구역(IDC)으로 설정하여
              출입을 통제한다. <br />
              - 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에
              대해 책임을 지지 않는다. 개개인이 본인의 개인정보를 보호하기
              위해서 자신의 ID와 비밀번호를 적절하게 관리하고 여기에 대한 책임을
              져야 한다. <br />
              - 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의
              상실, 유출, 변조, 훼손이 유발될 경우 회사는 즉각 이용자에게 그
              사실을 알리고 적절한 대책과 보상을 강구한다. <br />
              3. 물리적 대책<br />
              가. 인터넷 데이터센터(IDC) 접근 통제
            </p>
            <h5 class="title">제7조(개인정보 관련 의견 수렴)</h5>
            <p>
              ① 정보주체는 개인정보침해로 인한 구제를 받기 위하여
              개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
              분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타
              개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기
              바랍니다. <br />
              1. 개인정보 분쟁조정위원회 : (국번없이) 1833-6972
              (www.kopico.go.kr) <br />
              2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
              <br />
              3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)<br />
              4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
            </p>
            <h5 class="title">제제8조(개인정보 보호책임자 성명 및 연락처)</h5>
            <p>
              회사는 수집된 정보를 안전하게 이용할 수 있도록 최선을 다 하며,
              아래와 같이 개인정보 보호책임자를 지정하고 있다.<br />
              개인정보보호책임자 <br />
              이름 : 오경은 <br />
              직위 : OO <br />
              e-mail : wisenests@naver.com <br />
              개인정보 보호책임자의 역할 및 업무 <br />
              1. 개인정보의 수집, 이용, 제공 및 관리에 관한 업무총괄 <br />
              2. 개인정보보호정책 및 관련규정의 수립 <br />
              3. 소속직원 또는 제 3자에 의한 위법, 부당한 개인정보 침해행위에
              대한 점검 <br />
              4. 개인정보취급자 지정 및 관리 감독, 교육<br />
              5. 개인정보 제공 또는 공유업체 및 위탁업체의 개인정보 보호상황에
              대한 관리감독 <br />
              6. 기타 이용자의 개인정보를 위해 필요한 사항에 대한 최선의 대책
              마련
            </p>
            <h5 class="title">제9조(정보주체의 권리와 의무)</h5>
            <p>
              정보 제공자는 개인정보를 최신의 상태로 정확하게 입력하여 불의의
              사고를 예방해 주어야 한다.부정확한 정보로 인해 발생하는 사고의
              책임은 정보 제공자 자신에게 있으며 타인 정보의 도용 등 허위정보를
              입력할 경우 법적 책임을 질 수 있으며 개인정보와 관련한
              아이디(ID)의 비밀번호에 대한 보안유지책임은 해당 이용자 자신에게
              있다. 회사는 비밀번호에 대해 어떠한 방법으로도 개인에게 직접적으로
              질문하는 경우는 없으므로 타인에게 비밀번호가 유출되지 않도록
              각별히 주의하여야 한다. 제7조. 개인정보관련 기술적/관리적/물리적
              대책에서 명시한 것과 같이 공공장소에서 온라인상에서 접속해 있을
              경우에는 더욱 유의하여야 한다. 회사는 개인정보에 대한 의견수렴 및
              불만처리를 담당하는 개인정보 보호책임자 및 담당자를 지정하고 있고,
              제9조 와이즈네스트 개인정보 보호책임자 및 담당자의 소속-성명 및
              연락처에 명시되어 있다.
            </p>
            <h5 class="title">제11조(변경에 관한 사항)</h5>
            <p>이 개인정보취급방침은 2024년 7월 1일부터 적용된다.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "privacy",
  bodyClass: "privacy-page",
};
</script>
<style scoped>
div {
  font-family: "Pretendard-Light";
}
</style>
