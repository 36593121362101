<template>
  <div>
    <!-- page-header -->
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/main_1.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h2 class="title">부동산<br/>투자자문</h2>
          <p>
            시장의 정보와 클라이언트의 니즈를 원활히 소통하여
            <b>안전한 사업</b>을 시작할 수 있도록 도와드립니다.
          </p>
        </div>
      </div>
    </div>
    <!-- // page-header -->
    <!-- second section -->
    <div class="section section-tabs">
      <div class="container">      
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="image-container wise" style="background-color: white">
                <h4 class="title text-center">부동산 매입자문</h4>
              </div>
            </div>
            <div class="col-md-8 service-detail">
              <h5>• 권리분석 및 현황분석</h5>
              <h5>• 매물/임대 등 빅데이터 자료분석</h5>
              <h5>• 적정매입금 제안</h5>
              <h5>• 사업평가보고서 작성</h5>
            </div>
            <div class="col-12 col-md-4">
              <div class="image-container wise" style="background-color: white">
                <h4 class="title text-center">부동산 개발사업</h4>
              </div>
            </div>
            <div class="col-md-8 service-detail">
              <h5>• 현황분석 및 사업수지분석</h5>
              <h5>• 조달금 확보방안 제안</h5>
              <h5>• 기획설계 및 대안검토</h5>
              <h5>• 법률검토</h5>
            </div>
          </div>        
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="image-container wise" style="background-color: white">
                <h4 class="title text-center">부동산 매각자문</h4>
              </div>
            </div>
            <div class="col-md-8 service-detail">
              <h5>• 매각 및 임대차 대행</h5>
              <h5>• 사업방향 제안</h5>
              <h5>• 리스크 관리</h5>
              <h5>• 그 외 매입관련 자문 일체</h5>
            </div>
            <div class="col-12 col-md-4">
              <div class="image-container wise" style="background-color: white">
                <h4 class="title text-center">공모사업</h4>
              </div>
            </div>
            <div class="col-md-8 service-detail">
              <h5>• 사업구조에 따른 조직구성 제안</h5>
              <h5>• 리스크 관리</h5>
              <h5>• 대관업무</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- // section 2-->
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
export default {
  name: "ourService1",
  bodyClass: "ourService1-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
    };
  },
};
</script>
<style scoped>
div {
  font-family: "Pretendard-Light";
}

.service-detail {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left:30px;
}
</style>
