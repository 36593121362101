<template>
  <div class="overlap">
    <img class="hunters-race" alt="Hunters race" src="/img/wn/hunters-race-mybhn8kaaec-unsplash-1.png" />
    <div class="text-wrapper-2">Wisely, honestly and professionally</div>
    <p class="the-three-mottos-are">
      The three mottos are Wisenest's principles of helping clients by revealing the truth.
    </p>
  </div>
</template>

<script>
export default {
  name: "Overlap",
};
</script>

<style scoped>
.overlap {
  height: auto;
  position: relative;
  width: 100%;
}

.overlap .hunters-race {
  width: 100%;
  height: auto;
}

.overlap .text-wrapper-2 {
  color: #ffffff;
  font-family: "Jost", Helvetica;
  font-size: 4vw;
  font-weight: 500;
  left: 5%;
  letter-spacing: 0.1vw;
  line-height: 1.2;
  position: absolute;
  top: 40%;
  width: 90%;
}

.overlap .the-three-mottos-are {
  color: #ffffff99;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 2vw;
  font-weight: 400;
  left: 5%;
  letter-spacing: 0;
  line-height: 1.5;
  position: absolute;
  top: 50%;
  width: 90%;
}

/* 반응형 웹 스타일 */
@media (max-width: 1200px) {
  .overlap .text-wrapper-2 {
    font-size: 5vw;
    top: 35%;
  }

  .overlap .the-three-mottos-are {
    font-size: 2.5vw;
    top: 45%;
  }
}

@media (max-width: 768px) {
  .overlap .text-wrapper-2 {
    font-size: 6vw;
    top: 30%;
  }

  .overlap .the-three-mottos-are {
    font-size: 3vw;
    top: 40%;
  }
}

@media (max-width: 576px) {
  .overlap .text-wrapper-2 {
    font-size: 7vw;
    top: 25%;
  }

  .overlap .the-three-mottos-are {
    font-size: 4vw;
    top: 35%;
  }
}
</style>