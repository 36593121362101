<template>
  <div class="GNB">
    <div class="frame-2">
      <img class="frame-3" alt="Frame" src="/img/wn/frame-14.svg" />
      <div class="frame-4">
        <Greeting menu="Greeting" state="default" />
        <Greeting divClassName="greeting-instance" menu="Organization" state="default" />
        <Greeting divClassName="design-component-instance-node" menu="Our Service" state="default" />
        <Greeting menu="Contact US" state="default" />
      </div>
      <img class="element-2" alt="Element" src="/img/wn/2024-07-10-10-30-54-1.png" />
    </div>
  </div>
</template>

<script>
import Greeting from "../../components/Greeting.vue";

export default {
  name: "Gnb",
  components: {
    Greeting,
  },
};
</script>

<style>
.GNB {
  background-color: #000000e6;
  height: 92px;
  left: 0;
  position: fixed;
  top: 0;
  width: 1920px;
}

.GNB .frame-2 {
  height: 34px;
  left: 129px;
  position: relative;
  top: 32px;
  width: 1662px;
}

.GNB .frame-3 {
  height: 24px;
  left: 1590px;
  position: absolute;
  top: 2px;
  width: 72px;
}

.GNB .frame-4 {
  align-items: center;
  display: inline-flex;
  gap: 24px;
  justify-content: center;
  left: 554px;
  position: absolute;
  top: 4px;
}

.GNB .greeting-instance {
  margin-left: -4.5px !important;
  margin-right: -4.5px !important;
}

.GNB .design-component-instance-node {
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.GNB .element-2 {
  height: 41px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: -7px;
  width: 45px;
}
</style>