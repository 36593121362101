<template>
  <div>
    <!-- page-header -->
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/main_1.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h2 class="title">강의문의</h2>
          <p>
            다수의 강의 경험을 바탕으로 지식과 경험을 모두에게 공유하고자
            합니다.<br />당사의 소통창구의 언제든 열려있습니다.<br />
            강의 뿐만 아닌 부동산 개발사업 관련 문의 질의는 언제든 환영합니다.
          </p>
        </div>
      </div>
    </div>
    <!-- // page-header -->
    <!-- section 7 강의문의 -->
    <div class="section section-tabs">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <img class="hunters-race" alt="lesson" src="/img/section_7.png" />
          </div>
        </div>
        <div class="separator separator-primary"></div>
      </div>
    </div>
    <!-- // section 7 -->
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
export default {
  name: "ourService4",
  bodyClass: "ourService4-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
    };
  },
};
</script>
<style scoped>
div {
  font-family: "Pretendard-Light";
}
</style>
